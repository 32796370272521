<template>
    <div class="register-page">
        <el-tabs v-model="activeTab" @tab-click="handleTabClick">
            <el-tab-pane label="个人注册" name="1">
                <div class="content">
                    <el-form v-if="activeTab == 1" ref="registerForm" :model="registerForm" :rules="rules" label-width="0">
                        <el-form-item prop="nickName">
                            <el-input v-model="registerForm.nickName" placeholder="请设置您的百工宝昵称"></el-input>
                        </el-form-item>
                        <el-form-item prop="accoun">
                            <el-input v-model="registerForm.accoun" placeholder="请设置用于登录百工宝的账号"></el-input>
                        </el-form-item>
                        <el-form-item prop="pwd">
                            <el-input v-model="registerForm.pwd" type="password" placeholder="请设置您的账号密码"></el-input>
                        </el-form-item>
                        <el-form-item prop="confirmPwd">
                            <el-input v-model="registerForm.confirmPwd" type="password" placeholder="请再次输入您的账号密码"></el-input>
                        </el-form-item>
                        <el-form-item prop="phone">
                            <el-input v-model="registerForm.phone" placeholder="请输入手机号"></el-input>
                        </el-form-item>
                        <el-form-item prop="code">
                            <el-row :gutter="20">
                                <el-col :span="14">
                                    <el-input v-model="registerForm.code" placeholder="请输入验证码"></el-input>
                                </el-col>
                                <el-col :span="10">
                                    <el-button type="primary" :disabled="countdown > 0" @click="getCode">
                                        {{ countdown > 0 ? `${countdown}秒` : '获取验证码' }}
                                    </el-button>
                                </el-col>
                            </el-row>
                        </el-form-item>

                        <el-form-item>
                            <el-button type="primary" class="register-button" @click="register">个人注册</el-button>
                            <p class="agreement">注册即表示同意 <el-link>《账号服务协议》</el-link> 和 <el-link>《隐私协议》</el-link></p>
                            <p class="wechat-info">微信用户PC端绑定手机号后即可使用手机号登录</p>
                        </el-form-item>
                    </el-form>
                </div>
            </el-tab-pane>
            <el-tab-pane label="企业注册" name="2">
                <div class="content">
                    <el-form v-if="activeTab == 2" ref="registerForm" :model="registerForm" :rules="rules" label-width="0">
                        <el-form-item prop="organizationType">
                            <el-select style="width: 100%;" v-model="registerForm.organizationType" placeholder="请选择企业类型">
                                <el-option label="企业" value="1"></el-option>
                                <el-option label="机关事业" value="2"></el-option>
                                <el-option label="社团组织或团体" value="3"></el-option>
                                <el-option label="个体工商户" value="4"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item prop="nickName">
                            <el-input v-model="registerForm.nickName" placeholder="请设置您的百工宝昵称"></el-input>
                        </el-form-item>
                        <el-form-item prop="accoun">
                            <el-input v-model="registerForm.accoun" placeholder="请设置用于登录百工宝的账号"></el-input>
                        </el-form-item>
                        <el-form-item prop="pwd">
                            <el-input v-model="registerForm.pwd" type="password" placeholder="请设置您的账号密码"></el-input>
                        </el-form-item>
                        <el-form-item prop="confirmPwd">
                            <el-input v-model="registerForm.confirmPwd" type="password" placeholder="请再次输入您的账号密码"></el-input>
                        </el-form-item>
                        <el-form-item prop="full">
                            <el-input v-model="registerForm.full" placeholder="法人姓名"></el-input>
                        </el-form-item>
                        <el-form-item prop="phone">
                            <el-input v-model="registerForm.phone" placeholder="请输入手机号"></el-input>
                        </el-form-item>
                        <el-form-item prop="code">
                            <el-row :gutter="20">
                                <el-col :span="14">
                                    <el-input v-model="registerForm.code" placeholder="请输入验证码"></el-input>
                                </el-col>
                                <el-col :span="10">
                                    <el-button
                                        style="border-radius: 0 4px 4px 0;width: 120px;"
                                        :style="{ background: countdown>0 ? '#F5F5F5' : '#1C7EFF', color: countdown>0 ? '#666' : '#fff',height: countdown>0 ? '35px' : '40px', }"
                                        :disabled="countdown>0"
                                        @click="getCode">
                                        {{ countdown > 0 ? `${countdown}秒` : '获取验证码' }}
                                    </el-button>
                                </el-col>
                            </el-row>
                        </el-form-item>

                        <el-form-item>
                            <el-button type="primary" class="register-button" @click="register">机构注册</el-button>
                            <p class="agreement">注册即表示同意 <el-link>《账号服务协议》</el-link> 和 <el-link>《隐私协议》</el-link></p>
                            <p class="wechat-info">微信用户PC端绑定手机号后即可使用手机号登录</p>
                        </el-form-item>
                    </el-form>
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import Vue from "vue";

export default {
    data() {
        return {
            activeTab: '1',
            registerForm: {
                nickName: '',
                accoun: '',
                pwd: '',
                confirmPwd: '',
                full: '',
                phone: '',
                code: '',
                organizationType: '1', // 默认值
                regType:1,
                deviceTypes:1,
            },
            countdown: 0,
            timer: null,
            rules: {
                nickName: [
                    { required: true, message: '请输入昵称', trigger: 'blur' },
                    { min: 1, max: 10, message: '长度在 1 到 10 个字符', trigger: 'blur' }
                ],
                accoun: [
                    { required: true, message: '请输入账号', trigger: 'blur' },
                    { min: 6, max: 20, message: '长度在 6 到 20 个字符', trigger: 'blur' }
                ],
                pwd: [
                    { required: true, trigger: 'blur', validator: this.validatePwd }
                ],
                confirmPwd: [
                    { required: true, message: '确认密码不能为空', trigger: 'blur' },
                    { trigger: 'blur', validator: this.validateConfirmPwd }
                ],
                phone: [
                    {required: true, message: '手机号不能为空', trigger: 'blur'},
                    {pattern: /^\d{11}$/, message: '请输入正确的11位手机号', trigger: 'blur'}
                ],
                code: [
                    {required: true, message: '验证码不能为空', trigger: 'blur'},
                    {pattern: /^\d{6}$/, message: '请输入正确的6位验证码', trigger: 'blur'}
                ],
                full: [
                    {required: true, message: '机构法人不能为空', trigger: 'blur'},
                    {pattern: /^[\u4e00-\u9fa5]+$/, message: '请输入正确的姓名', trigger: 'blur'}
                ],
                organizationType: [
                    {required: true, message: '请选择机构类别', trigger: 'change'}
                ]
            }
        };
    },
    methods: {
        handleTabClick() {
            this.regType = this.activeTab;
        },

        //获取验证码
        getCode() {
            const that = this;
            if (this.countdown > 0) return;
            this.$refs.registerForm.validateField('phone', (errorMsg) => {
                if (!errorMsg) {
                    this.startCountdown();
                    Vue.prototype.openLoading("请稍后...");
                    this.newApi.sendSms({"mobile":that.registerForm.phone, smsType: 5}).then((ret)=>{
                        that.utils.sus(ret.data);
                    }).catch((err)=>{
                        that.utils.err(err);
                    });
                }
            })
        },

        //倒计时
        startCountdown() {
            // 获取验证码逻辑
            if (this.countdown > 0) return;
            this.countdown = 60;
            this.timer = setInterval(() => {
                if (this.countdown > 0) {
                    this.countdown--;
                } else {
                    clearInterval(this.timer);
                }
            }, 1000);
        },

        //立即注册
        register() {
            var that = this;
            this.$refs.registerForm.validate((valid) => {
                if (valid) {
                    Vue.prototype.openLoading("请稍后...");
                    that.newApi.userRegister(this.registerForm).then((ret)=>{
                        if (ret.isSuccess === 1){
                            that.utils.sus("注册成功");
                            let param = {
                                accoun: that.registerForm.accoun,
                                pwd: that.registerForm.pwd
                            }
                            localStorage.token = "";
                            localStorage.uuid = "";
                            setTimeout(that.newApi.getLoginAccount(param).then((res)=>{
                                if(res.isSuccess == 1){
                                    localStorage.token = res.data.token;
                                    localStorage.uuid = res.data.uuid;
                                    that.$store.commit("get_user_info");
                                    //记录登录时间
                                    that.newApi.updateLoginTime({uuid: res.data.uuid});
                                    this.$router.go(-1)
                                }
                            }),500)
                        }
                    })
                } else {
                    console.log('注册失败');
                    return false;
                }
            });
        },
        validatePwd(rule, value, callback) {
            if (value === '') {
                callback(new Error('请输入密码'));
            } else if (value.length < 6) {
                callback(new Error('密码长度不能少于6位'));
            } else {
                if (this.registerForm.confirmPwd !== '') {
                    this.$refs.registerForm.validateField('confirmPwd');
                }
                callback();
            }
        },
        validateConfirmPwd(rule, value, callback) {
            if (value === '') {
                callback(new Error('请再次输入密码'));
            } else if (value !== this.registerForm.pwd) {
                callback(new Error('两次输入密码不一致!'));
            } else {
                callback();
            }
        }
    },
    beforeDestroy() {
        if (this.timer) {
            clearInterval(this.timer);
        }
    }
};
</script>

<style scoped>
.register-page {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    height: 100vh;
}

.content {
    text-align: center;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
}

.register-button {
    width: 100%;
    background-color: #409EFF;
    border: none;
}

.agreement {
    margin: 20px 0;
    text-align: center;
    font-size: 12px;
    color: #999;
}

.wechat-info {
    text-align: center;
    font-size: 12px;
    color: #999;
    margin-top: 20px;
}

/* 响应式设计 */
@media (max-width: 600px) {
    .content {
    }

    .register-button {
        font-size: 14px;
    }

    .agreement, .wechat-info {
        font-size: 10px;
    }
}
</style>
